<template>
  <v-card style="width: 100%" @click="goToDetails">
    <v-card-subtitle class="d-flex flex-row">
      <div style="width: 100px; height: 100px;">
        <v-img :src="blog.cover" width="100%" height="100%" />
      </div>
      <div class="ml-3">
        <div class="break-word font-14 font-bold">
          {{ blog.title }}
        </div>
        <div
          class="font-12 url-button d-flex align-center mt-3"
          @click.stop="goToAgencyProfile"
          @mousedown.stop
          @touchstart.stop
        >
          <div class="mr-2">
            <editable-avatar
              :image="blog.poster_image"
              :editable="false"
              :shadow="false"
              :size="30"
            />
          </div>
          {{ blog.poster_name }}
        </div>
      </div>
    </v-card-subtitle>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-card>
</template>
<script>
import EditableAvatar from "../../components/EditableAvatar.vue";

export default {
  props: {
    blog: Object
  },
  data() {
    return {
      snackbar: false,
      errorMessage: null,
      deleteDialog: false,
      loading: false
    };
  },
  methods: {
    goToDetails() {
      this.$router.push({
        name: "blog-details",
        query: { _id: this.blog._id }
      });
    },
    goToAgencyProfile() {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: this.blog.poster }
      });
    }
  },
  components: { EditableAvatar }
};
</script>
<style scoped>
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
