<template>
  <keep-alive>
    <v-container
      fluid
      class="d-flex flex-column h-100 pa-0 min-height-inherit"
      :class="{ 'max-width-1500': profileType != 'Admin' }"
    >
      <v-alert
        dismissible
        v-for="notification in notifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="success"
      >
        {{ notification }}
      </v-alert>
      <v-alert
        dismissible
        v-for="notification in errorNotifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="error"
      >
        {{ notification }}
      </v-alert>
      <div
        :class="{ 'max-width-1500': profileType != 'Admin' }"
        class="d-flex flex-row h-100 pa-0 min-height-inherit"
      >
        <agency-left-section
          class="hidden-sm-and-down"
          v-if="showLeftPanel && profileType == 'Agency'"
        />
        <volunteer-left-section
          class="hidden-sm-and-down"
          v-else-if="showLeftPanel && profileType == 'Volunteer'"
        />
        <admin-left-section
          class="hidden-sm-and-down"
          v-else-if="profileType == 'Admin'"
        />
        <router-view />
        <blog-right-section class="hidden-md-and-down" v-if="!showLeftPanel" />
        <agency-right-section
          class="hidden-md-and-down"
          v-else-if="profileType == 'Agency'"
        />
        <volunteer-right-section
          class="hidden-md-and-down"
          v-else-if="profileType == 'Volunteer'"
        />
        <admin-right-section
          class="hidden-md-and-down"
          v-else-if="profileType == 'Admin'"
        />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapState } from "vuex";
import AgencyLeftSection from "../agency/AgencyLeftSection.vue";
import AgencyRightSection from "../agency/AgencyRightSection.vue";
import VolunteerLeftSection from "../volunteer/VolunteerLeftSection.vue";
import VolunteerRightSection from "../volunteer/VolunteerRightSection.vue";
import AdminLeftSection from "../admin/home/AdminHomeSideMenu";
import AdminRightSection from "../admin/AdminRightSection.vue";
import BlogRightSection from "./BlogRightSection.vue";
export default {
  components: {
    AgencyRightSection,
    AgencyLeftSection,
    BlogRightSection,
    VolunteerRightSection,
    VolunteerLeftSection,
    AdminLeftSection,
    AdminRightSection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: [],
      showLeftPanel: false
    };
  },
  methods: {
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    }
  },
  computed: {
    ...mapState("auth", {
      profileType: "type"
    })
  },
  watch: {
    "$route.name": function(newValue) {
      if (newValue == "blog-feeds") {
        this.showLeftPanel = true;
      } else {
        this.showLeftPanel = false;
      }
    }
  },
  mounted() {
    this.notifications = this.$route.params.notifications;
    this.errorNotifications = this.$route.params.error;
    if (this.$route.name == "blog-feeds") {
      this.showLeftPanel = true;
    } else {
      this.showLeftPanel = false;
    }
  }
};
</script>
<style scoped></style>
